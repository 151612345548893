import type { Hit, LegacySearchMethodProps, SearchMethodParams } from '@algolia/client-search'
import type { RequestOptions } from '@algolia/transporter'
import type { DocSearchProps, DocSearchTransformClient } from '@docsearch/react'

const consoleItems = [
  { label: 'Console Dashboard', url: 'https://transloadit.com/c/' },
  { label: 'Log into the Console', url: 'https://transloadit.com/c/login/' },
  { label: 'Manage Addons', url: 'https://transloadit.com/c/addons/' },
  { label: 'Manage App Settings', url: 'https://transloadit.com/c/settings/' },
  { label: 'Manage Assemblies', url: 'https://transloadit.com/c/assemblies/' },
  { label: 'Manage Billing', url: 'https://transloadit.com/c/billing/' },
  { label: 'Manage Collaborators', url: 'https://transloadit.com/c/collaborators/' },
  { label: 'Manage Credentials', url: 'https://transloadit.com/c/template-credentials/' },
  { label: 'Manage Templates', url: 'https://transloadit.com/c/templates/' },
  { label: 'Manage User Settings', url: 'https://transloadit.com/c/user/settings/' },
]

interface ConsoleSearchResult {
  hits: Hit[]
  processingTimeMS: number
  query: string
  params: string
}

async function consoleSearch(searchString: string | undefined): Promise<ConsoleSearchResult> {
  if (searchString === undefined) {
    return {
      hits: [],
      processingTimeMS: 0,
      query: '',
      params: '',
    }
  }

  const foundItems = consoleItems.filter((item) =>
    item.label.toLowerCase().includes(searchString.toLowerCase()),
  )

  const returnItems = []

  for (const item of foundItems) {
    const matchedWords = item.label.match(new RegExp(searchString, 'gi'))

    const markedLabel = item.label.replace(new RegExp(searchString, 'gi'), '<mark>$&</mark>')

    const lvl0 = 'Console (login required)'

    returnItems.push({
      content: item.label,
      url: item.url,
      hierarchy: {
        lvl0,
        lvl1: item.label,
      },
      type: 'lvl1',
      objectID: `console-${item.label}`,
      _snippetResult: {
        content: {
          value: markedLabel,
          matchLevel: 'full',
        },
        hierarchy: {
          lvl1: {
            value: markedLabel,
            matchLevel: 'full',
          },
        },
      },
      _highlightResult: {
        content: {
          value: markedLabel,
          matchLevel: 'full',
          fullyHighlighted: false,
          matchedWords: matchedWords ?? [],
        },
        url: {
          value: item.url,
          matchLevel: 'none',
          matchedWords: [],
        },
        hierarchy: {
          lvl0: {
            value: lvl0,
            matchLevel: 'none',
            matchedWords: [],
          },
          lvl1: {
            value: markedLabel,
            matchLevel: 'full',
            fullyHighlighted: false,
            matchedWords: matchedWords ?? [],
          },
        },
      },
    } satisfies Hit)
  }

  return {
    hits: returnItems,
    processingTimeMS: 0,
    query: searchString,
    params: '',
  }
}

export const docsearchConfig: DocSearchProps = {
  appId: '5WUMEZP4XY',
  indexName: 'static_content',
  apiKey: '5f5880022fa46cfdd149ba92a9c3c1b1',
  placeholder: 'Search Transloadit.com',
  transformSearchClient(searchClient: DocSearchTransformClient) {
    const originalSearch = searchClient.search.bind(searchClient)
    return {
      ...searchClient,
      async search<T>(
        searchParams: SearchMethodParams | LegacySearchMethodProps,
        requestOptions?: RequestOptions,
      ) {
        const response = await originalSearch<T>(searchParams, requestOptions)
        const firstResult = response.results[0]

        let q: string | undefined
        if ('requests' in searchParams) {
          if (searchParams.requests.length > 0) {
            if ('query' in searchParams.requests[0]) {
              q = searchParams.requests[0].query
            }
          }
        }

        const extraItems = q ? await consoleSearch(q) : { hits: [] }

        console.log({ searchParams, extraItems })

        if (extraItems.hits?.length > 0 && 'hits' in firstResult) {
          firstResult.hits = [...firstResult.hits, ...extraItems.hits] as Hit<T>[]
        }

        return response
      },
    }
  },
  transformItems: (items) => {
    return items.map((item) => ({
      ...item,
      // Make sure we don't navigate away to transloadit.com from local dev
      url:
        process.env.NODE_ENV !== 'development'
          ? item?.url
          : item?.url?.replace('https://transloadit.com/', '/'),
    }))
  },
}
